var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.slide
    ? _c(
        "div",
        { staticClass: "service-configuration-slide-container" },
        [
          _vm.showDomainTransferHeading
            ? _c("slide-heading", { attrs: { heading: "Domain Status" } })
            : _c("slide-heading", {
                attrs: { "html-heading": _vm.slide.heading },
              }),
          _vm.showSlideDescription
            ? _c("slide-image-and-description", {
                attrs: {
                  "override-description": _vm.slideDescription,
                  "allow-images": true,
                },
              })
            : _vm._e(),
          _vm.serviceType === _vm.serviceTypes.domain
            ? _c("domain-service-configuration", {
                attrs: { slide: _vm.slide },
                on: {
                  "next-slide": function ($event) {
                    return _vm.$emit("next-slide")
                  },
                },
              })
            : _vm.serviceType === _vm.serviceTypes.hosting
            ? _c("hosting-service-configuration", {
                attrs: { slide: _vm.slide },
                on: {
                  "next-slide": function ($event) {
                    return _vm.$emit("next-slide")
                  },
                },
              })
            : _vm.serviceType === _vm.serviceTypes.ssl
            ? _c("ssl-service-configuration", {
                attrs: { slide: _vm.slide },
                on: {
                  "next-slide": function ($event) {
                    return _vm.$emit("next-slide")
                  },
                },
              })
            : _c("div", [
                _c("p", [_vm._v("\n      Unable to load Service\n    ")]),
              ]),
        ],
        1
      )
    : _c("div", [
        _c(
          "div",
          { staticClass: "d-flex justify-content-center mt-2 mb-2" },
          [
            _c("ct-centered-spinner", {
              attrs: { variant: "primary", large: "" },
            }),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }